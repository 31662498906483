.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem .2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.light_mode .contact__option {
    background: var(--color-bg-lightmode);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.light_mode .contact__option:hover {
    background: var(--color-bg-lightmode-hover);
    border-color: transparent;
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.light_mode .contact__option-icon {
    color: var(--color--bg);
}

.light_mode .contact__option {
    color: var(--color--bg);
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

.light_mode .contact__option a {
    color: var(--color-lightmode);
}

/* ================== FORM ================= */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.input {
    position: relative;
}

.input__field {
    box-sizing: border-box;
    display: block;
    width: 100%;
    border: 2px solid var(--color-light);
    padding: calc(0.5rem * 2) 1rem;
    color: var(--color-white);
    background: transparent;
    border-radius: 1rem;
}

.light_mode .input__field {
    border: 2px solid var(--color--bg);
    color: var(--color--bg);
}

.input__label {
    position: absolute;
    left: 1rem;
    top: 0;
    padding: calc(.5rem * 0.75) calc(.5rem * 1.5);
    margin: calc(.5rem * 0.75 + 3px) calc(.5rem * .5);
    background: var(--color--bg);
    white-space: nowrap;
    transform: translate(0, 0);
    transform-origin: 0 0;
    transition: transform 120ms ease-in;
    font-weight: bold;
    line-height: 1.2;
}

.light_mode .input__label {
    background: white;
}

.input .input__label :focus,
.input .input__label:not(:placeholder-shown) {
    transform: translate(.25rem, -65%) scale(.8);
    color: var(--color-white);
}

.light_mode .input .input__label :focus,
.light_mode .input .input__label:not(:placeholder-shown) {
    color: var(--color--bg);
}

button {
    font-weight: 600;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ============== */
@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ============== */
@media screen and (max-width: 600px) {

    .container.contact__container {
        width: var(--container-width-sm);

    }
}