.nav_container {
    background-color: #131322;
    box-shadow: 0px 0px 20px 8px #111111;
    width: 100%;
    height: max-content;
    padding: 4px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 9;
    transition: .3s all linear;
    color: var(--color-primary);
}

.light_mode .nav_container {
    background-color: #fff !important;
    color: var(--color-lightmode);
}

.nav_container.active {
    background-color: transparent !important;
    transition: .3s all linear;
    backdrop-filter: blur(0px);
    box-shadow: 0px 0px 0px 0px #111111;
}

.nav_container button {
    background-color: transparent;
    color: var(--color-primary);
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav_container button:hover {
    color: grey;
}

.light_mode .nav_container button {
    color: black;
}

.light_mode .nav_container button:hover {
    color: var(--color-primary);
}

@media screen and (max-width: 600px) {
    .nav_container {
        padding: 4px 20px;
    }
}