.services__container-main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.service-main {
    background: var(--color-bg-variant);
    border-radius: 2rem;
    height: fit-content;
    padding: 3rem;
}

.light_mode .service-main {
    background: var(--color-bg-lightmode);
}

.service__head-main h3 {
    margin-bottom: 1rem;
}

.light_mode .service__head-main h3 {
    color: var(--color--bg);
}

.service__btn-main {
    display: flex;
    gap: 1rem;
    width: max-content;
}

.light_mode .service__btn-main {
    color: var(--color-lightmode);
}

.service__head-icon-main {
    color: var(--color-primary);
    margin-top: 0.35rem;
}

.light_mode .service__head-icon-main {
    color: var(--color-lightmode);
}

.service__btn-main span {
    display: inline-block;
    transition: 0.2s ease-in;
}

.service__btn-main:hover span {
    transform: translateX(50%);
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ============== */
@media screen and (max-width: 1024px) {
    .services__container-main {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .service-main {
        width: auto;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ============== */
@media screen and (max-width: 600px) {
    .services__container-main {
        grid-template-columns: 1fr;
        gap: 1.5;
    }
}

/* ================== Services Modal ===================== */

body.active_modal {
    overflow-y: hidden;
    z-index: 999 !important;
    transition: .2s all linear;
}

.services__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0px;
}

.services_overlay {
    background: rgba(49, 49, 49, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    transition: .2s all linear;
    visibility: hidden;
    transition: .2s all linear;
}

.services_overlay.active {
    visibility: visible;
    transition: .2s all linear;
}

.service {
    background: var(--color-bg-variant);
    width: max-content;
    border-radius: 2rem;
    border: 1px solid var(--color-primary);
    padding: 1rem 3rem;
    position: relative;
    transition: .2s all linear;
    transform: scale(0);
}

.light_mode .service {
    background: var(--color-white);
    border: none;
}

.services_overlay.active .service {
    transition: .2s all linear;
    transform: scale(1);
}

.services__close-icon {
    color: var(--color-primary);
    position: absolute;
    top: 1rem;
    right: 2rem;
    scale: 1.5;
}

.light_mode .services__close-icon {
    color: var(--color-lightmode);
}

.service__head {
    text-align: center;
    padding: 1rem;
}

.service__head h3 {
    color: var(--color-primary);
}

.light_mode .service__head h3 {
    color: var(--color-lightmode);
}

.light_mode .service__head small {
    color: var(--color--bg);
}

.service__list {
    padding: 1rem 2rem;
    color: var(--color-light);
}

.light_mode .service__list {
    color: var(--color-bg-variant);
}

.service__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.service__list p {
    font-size: 0.9rem;
}

.service__list-icon {
    color: var(--color-primary);
    margin-top: 3px;
}

.light_mode .service__list-icon {
    color: var(--color--bg);
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ============== */
@media screen and (max-width: 600px) {
    .service {
        padding: 0;
    }

    .service__list p {
        font-size: 0.8rem;
    }

    .services__close-icon {
        right: 1rem;
    }

    .service__head {
        margin-top: 1rem;
    }


}