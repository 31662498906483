header {
    height: 100vh;
    padding-top: 4rem;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* =============== CTA ===============*/
.cta {
    margin-top: 2rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/*================ HEADER SOCIALS =============== */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    top: 210px;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

.light_mode .header__socials::after {
    background: var(--color-lightmode);
    width: 2px;
}

.header__socials a {
    background: transparent;
    padding: 0.7rem;
    border-radius: 50%;
    display: flex;
    font-size: 1.1rem;
    background: rgba(0, 0, 0, 0.3);
}

.light_mode .header__socials a {
    color: var(--color-lightmode);
    background: rgba(0, 0, 0, 0.1);
}

.header__socials a:hover {
    background: var(--color-primary);
    color: var(--color-white);
}

.light_mode .header__socials a:hover {
    color: var(--color-white);
    background: var(--color-lightmode);
}


/*=============== ME =================*/
.me {
    width: 18rem;
    height: 18rem;
    position: absolute;
    left: calc(50% - 8.5rem);
    margin-top: 1.5rem;
    overflow: hidden;
    border-radius: 0.5rem;
}

.light_mode .me {
    box-shadow: 1px 1px 12px 1px #111111;
    border-radius: 1.5rem;
}

/*================= SCROLL DOWN ===================*/
.scroll__down {
    position: absolute;
    right: -2.3rem;
    top: 450px;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

.light_mode .scroll__down {
    color: var(--color-lightmode);
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ============== */
@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
        overflow: hidden;
    }

    .header__container {
        height: 100vh;
    }

    .header__socials {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.8rem;
        position: absolute;
        left: 10;
        top: 190px;
    }

    .header__socials::after {
        content: "";
        width: 1px;
        height: 2rem;
        background: var(--color-primary);
    }

    .header__socials a {
        background: transparent;
        padding: 0.7rem;
        border-radius: 50%;
        display: flex;
        font-size: 1.1rem;
        background: rgba(0, 0, 0, 0.3);
    }

    .scroll__down {
        position: absolute;
        right: -2.3rem;
        top: 450px;
        transform: rotate(90deg);
        font-weight: 300;
        font-size: 0.9rem;
    }

}

/* ================ MEDIA QUERIES (SMALL DEVICES) ============== */
@media screen and (max-width: 600px) {

    header {
        height: 90vh;
        justify-content: center;
        margin: 0px !important;
        padding: 0px !important;
        padding-top: 7rem;
        overflow: hidden;
    }

    .header__container {
        height: 55%;
    }

    .header__socials {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        position: absolute;
        left: 10;
        top: 225px;
    }

    .header__socials::after {
        content: "";
        width: 1px;
        height: 2rem;
        background: var(--color-primary);
    }

    .header__socials a {
        background: transparent;
        padding: 0.7rem;
        border-radius: 50%;
        display: flex;
        font-size: 1.1rem;
        background: rgba(0, 0, 0, 0.3);
    }

    .scroll__down {
        display: none;
    }

    .me {
        width: 16rem;
        height: 16rem;
        position: absolute;
        left: calc(50% - 7.5rem);
        margin-top: 3rem;
        overflow: hidden;
    }

}